import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBoxOpen,
  faChartPie,
  faCog,
  faFileAlt,
  faHandHoldingUsd,
  faSignOutAlt,
  faTable,
  faTimes,
  faCalendarAlt,
  faMapPin,
  faInbox,
  faRocket,
  faCalendar,
  faCashRegister,
  faBoxes,
  faUserNurse,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
  Row,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { logoutAction } from "../actions/authActions";

import { Routes } from "../routes";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import DefaultUser from "../assets/img/team/default-user.png";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { limpiarFiltroAction } from "../actions/filtroActions";
import Logo from "../assets/img/logo.png";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const { user } = useSelector((state) => state.auth);
  const { respuestaFiltro } = useSelector((state) => state.filtro);

  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logoutAction());
  };

  const Menu = () => {
    let menuRender = [];

    user.menu.forEach((menu) => {
      if (menu.nombre == "Reportes") {
        return;
      }

      if (menu.funciones) {
        const funciones = menu.funciones_relations.map((funcion) => (
          <NavItem
            title={funcion.nombre}
            link={funcion.url}
            icon={funcion.icono}
          />
        ));

        const menuSection = (
          <CollapsableNavItem
            eventKey={`${menu.nombre}/`}
            title={menu.nombre}
            icon={menu.icono}
          >
            {funciones}
          </CollapsableNavItem>
        );

        menuRender.push(menuSection);
      } else {
        menuRender.push(
          <NavItem title={menu.nombre} link={menu.url} icon={menu.icono} />
        );
      }
    });

    const reporteMenu = user.menu.filter((menu) => {
      return menu.nombre == "Reportes";
    });

    if (reporteMenu.length > 0) {
      const reportesCategorias = user.reportes_categorias.map((categoria) => {
        const reportes = user.reportes.map((reporte) => {
          if (reporte.id_reporte_categoria == categoria.id) {
            return (
              <NavItem
                title={reporte.descripcion}
                link={`/reportes/${reporte.nombre}`}
              />
            );
          }
        });

        return (
          <CollapsableNavItem
            eventKey={`${categoria.nombre}/`}
            title={categoria.nombre}
          >
            {reportes}
          </CollapsableNavItem>
        );
      });

      const reportesSection = (
        <CollapsableNavItem
          eventKey={`Reportes/`}
          title="Reportes"
          icon={reporteMenu[0].icono}
        >
          {reportesCategorias}
        </CollapsableNavItem>
      );

      menuRender.push(reportesSection);
    }

    return menuRender;
  };

  const onClickItem = () => {
    dispatch(limpiarFiltroAction());
  };

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <i className={icon}></i>{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link
          {...linkProps}
          target={target}
          className={classNames}
          onClick={onClickItem}
        >
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <i className={icon}></i>{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={Logo} className="navbar-brand-light" style={{height:"45px"}} />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-black`}
          style={{ boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)" }}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={DefaultUser}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6 className="text-black">{user.name}</h6>
                  <Button
                    onClick={logOut}
                    variant="secondary"
                    size="xs"
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Salir
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <Row className="d-none d-md-block">
                <div className="logo-container">
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ height: "auto", width: "100px" }}
                  />
                </div>
                <div className="horizontal-line"></div>
              </Row>
              <NavItem
                title="Dashboard"
                link={Routes.DashboardOverview.path}
                icon="fas fa-chart-pie"
              />
              {
                //<NavItem external title="Messages" link="https://demo.themesberg.com/volt-pro-react/#/messages" target="_blank" badgeText="Pro" icon={faInbox} />
              }

              <Menu />

              <Dropdown.Divider className="my-3 border-indigo" />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
