import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  Row,
  Col,
  Tabs,
  Tab,
  Button,
  Table,
  Form,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { faCalendarAlt, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  actualizarConfiguracionAction,
  getConfiguracionAction,
} from "../../actions/configuracionActions";
import Datetime from "react-datetime";
import moment from "moment-timezone";

export default () => {
  const dispatch = useDispatch();
  const { configuracion } = useSelector((state) => state.configuracion);

  const [parametros, guardarParametros] = useState({});

  useEffect(() => {
    if (configuracion.length == 0) {
      dispatch(getConfiguracionAction());
    }

    guardarParametros(configuracion);
  }, [configuracion]);

  const getTabs = () => {
    let tabs = configuracion.map((conf) => {
      return conf.tab;
    });
    const uniqueTabs = tabs.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );

    return uniqueTabs;
  };

  const timeConstraints = {
    minutes: {
      step: 30,
    },
  };

  const getParamsTab = (id_tab) => {
    const paramsHtml = parametros.map((conf) => (
      <>
        {conf.id_tab_configuracion === id_tab && (
          <tr>
            <td>{conf.parametro}</td>
            <td>
              {(conf.tipo == "text" || conf.tipo == "number") && (
                <Form.Control
                  onChange={onChangeForm}
                  type={conf.tipo}
                  name={conf.parametro}
                  value={conf.valor}
                />
              )}
              {conf.tipo === "boolean" && (
                <Form.Check
                  type="checkbox"
                  name={conf.parametro}
                  onChange={onChangeForm}
                  defaultChecked={conf.valor == "true"}
                />
              )}
              {conf.tipo === "hora" && (
                <Form.Group className="mb-3">
                  <Datetime
                    closeOnSelect={false}
                    utc={true}
                    value={conf.valor}
                    initialViewMode="time"
                    dateFormat={false}
                    timeFormat={true}
                    onChange={(moment) => setHora(moment, conf.parametro)}
                    inputProps={{
                      name: conf.parametro,
                    }}
                    timeConstraints={timeConstraints}
                    renderInput={(propsDate, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={
                            conf.valor
                              ? moment.utc(conf.valor).format("HH:mm")
                              : ""
                          }
                          name={conf.parametro}
                          placeholder="Selecciona una hora*"
                          onFocus={openCalendar}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              )}
            </td>
          </tr>
        )}
      </>
    ));

    return <>{paramsHtml}</>;
  };

  const setHora = (moment, nombreParametro) => {
    const parametrosActualizados = parametros.map((parametro) => {
      if (parametro.parametro === nombreParametro) {
        parametro.valor = moment;
      }

      return parametro;
    });

    guardarParametros(parametrosActualizados);
  };

  const onChangeForm = (e) => {
    const parametrosActualizados = parametros.map((parametro) => {
      if (parametro.parametro === e.target.name) {
        const valor =
          e.target.type == "checkbox"
            ? String(e.target.checked)
            : e.target.value;
        parametro.valor = valor;
      }

      return parametro;
    });

    guardarParametros(parametrosActualizados);
  };

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(actualizarConfiguracionAction(parametros));
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Configuración</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <h5 className="mb-4" style={{ marginLeft: "10px" }} onClick={getTabs}>
        {" "}
        Configuración General{" "}
      </h5>

      <Row>
        <Col>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
          >
            <Card.Body>
              <Form onSubmit={submitForm}>
                {configuracion.length > 0 && (
                  <Tabs id="uncontrolled-tab-example" className="mb-3">
                    {getTabs().map((value, index) => {
                      return (
                        <Tab eventKey={value.id} title={value.tab}>
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>Parametro</th>
                                <th>Valor</th>
                              </tr>
                            </thead>
                            <tbody>{getParamsTab(value.id)}</tbody>
                          </Table>
                        </Tab>
                      );
                    })}
                  </Tabs>
                )}

                <Row>
                  <Col>
                    <Button
                      className="mx-auto d-block mt-5"
                      type="submit"
                      variant="secondary"
                    >
                      Actualizar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
