import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Modal,
  Button,
  Form,
  InputGroup,
  Row,
  ListGroup,
  Container,
  Nav,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
  ThemeProvider,
  Image,
  Tab as TabReact,
  Tabs as TabsReact,
} from "@themesberg/react-bootstrap";
import {
  getAgendaAction,
  eliminarAgendaAction,
  agregarAgendaAction,
  getHorariosDisponiblesAction,
  confirmarCitaAction,
  actualizarAgendaAction,
} from "../../../actions/agendaActions";
import {
  comenzarAgregarPacienteAction,
  getPacientesAction,
} from "../../../actions/pacientesActions";
import RevoCalendar from "revo-calendar-jzepeda";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faCalendarAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clienteAxios from "../../../config/axios";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import DefaultUser from "../../../assets/img/team/default-user.png";
import "react-datetime/css/react-datetime.css";
import Filtro from "../../../components/Filtro";
import { formatAMPM } from "../../../helpers";
import { getEmpleadosConAgendaActivaAction } from "../../../actions/empleadosActions";
import { limpiarFiltroAction } from "../../../actions/filtroActions";
import AgregarPaciente from "../../../pages/Pacientes/AgregarPaciente";
import { Fragment } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { SplitButton } from "primereact/splitbutton";
import { Avatar } from "primereact/avatar";
import DefaultPicture from "../../../assets/img/team/default-user.png";
import {
  Tabs,
  DragTabList,
  PanelList,
  Panel,
  ExtraButton,
  Tab,
  TabList,
} from "@react-tabtab-next/tabtab";
import * as customStyle from "./tabsTheme";
import { Card as PrimeCard } from "primereact/card";

export default () => {
  const dispatch = useDispatch();

  const tabNode = useRef(null);
  const tabList = useRef(null);

  const { agendas, horarios_disponibles } = useSelector(
    (state) => state.agenda
  );
  const { pacientes } = useSelector((state) => state.pacientes);
  const { empleados } = useSelector((state) => state.empleados);
  const [activeTab, setActiveTab] = useState(0);

  const [id_empleado_agenda, setDoctorAgenda] = useState(null);

  const { respuestaFiltro } = useSelector((state) => state.filtro);

  const [fechaSeleccionada, setFechaSeleccionada] = useState(
    moment().format("YYYY-MM-DD")
  );

  const modelo = "Agenda.Agenda";
  const opcionesFiltro = [
    {
      valor: "cliente_proximas_citas",
      label: "Nombre cliente",
      tipo: "text",
      criteria: ["agenda"],
    },
    {
      valor: "uuid",
      label: "Folio",
      tipo: "text",
      criteria: ["agenda"],
    },
  ];

  useEffect(() => {
    dispatch(getEmpleadosConAgendaActivaAction());
    dispatch(getPacientesAction());
  }, []);

  useEffect(() => {
    if (id_empleado_agenda == null && empleados.length > 0) {
      setDoctorAgenda(empleados[0].id);
      dispatch(getAgendaAction(empleados[0].id));
      dispatch(
        getHorariosDisponiblesAction(
          empleados[0].id,
          moment().format("YYYY-MM-DD")
        )
      );
    }
  }, [empleados]);

  const [fecha, setFecha] = useState("");
  const [fechaForm, setFechaForm] = useState("");
  const [titulo, setTitulo] = useState("");
  const [nota, setNota] = useState(null);
  const [modalEvent, setModalEvent] = useState(false);
  const [agenda, setAgenda] = useState(null);

  const tabItems = useMemo(() => {
    return empleados.map((empleado, index) => {
      return (
        <Tab key={index}>
          <div className="media d-flex align-items-center">
            <Image
              src={DefaultPicture}
              className="user-avatar md-avatar rounded-circle"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                minWidth: "1.5rem",
                minHeight: "1.5rem",
              }}
            />
            <div className="media-body ms-2 text-dark align-items-center">
              <span className="mb-0 fw-bold">
                {" "}
                {empleado.nombre} {empleado.apellido_paterno}
              </span>
            </div>
          </div>
        </Tab>
      );
    });
  }, [empleados]);

  const panelItems = useMemo(() => {
    return empleados.map((tab, index) => {
      return <Panel key={index}></Panel>;
    });
  }, [empleados]);

  useEffect(() => {
    console.log(fecha);
  }, [fecha]);

  useEffect(() => {
    if (empleados[activeTab]) {
      onChangeDoctorAgenda(empleados[activeTab].id);
    }
  }, [activeTab]);

  const [state, setState] = useState({
    query: "",
    options: [],
    isLoading: false,
    clienteSeleccionado: null,
  });

  var _cacheCliente = {};

  const _handleInputChangeCliente = (query) => {
    state.query = query;
  };

  const makeAndHandleRequestCliente = (query) => {
    const config = { params: { query } };

    return clienteAxios.get("typeahead/clientes", config).then((res) => {
      const options = res.data.data;
      return { options };
    });
  };

  const _handleSearchCliente = (query) => {
    if (_cacheCliente[query]) {
      setState({
        ...state,
        options: _cacheCliente[query].options,
      });
      return;
    }

    setState({
      ...state,
      isLoading: true,
    });

    makeAndHandleRequestCliente(query).then((resp) => {
      _cacheCliente[query] = { ...resp };
      setState({
        ...state,
        isLoading: false,
        options: resp.options,
      });
    });
  };

  const onChangeCliente = (selectedOption) => {
    if (selectedOption.length > 0) {
      setState({
        ...state,
        clienteSeleccionado: selectedOption[0],
      });
    } else {
      setState({
        ...state,
        clienteSeleccionado: null,
      });
    }
  };

  const addEvent = (date) => {
    setAgenda(null);
    setFecha(date);
    setTitulo(null);
    setModalEvent(true);
    setNota(null);
  };

  const onConfirmEvent = (e) => {
    e.preventDefault();

    const data = {
      fecha: fechaForm,
      titulo: titulo,
      id_cliente: state.clienteSeleccionado?.id,
      id_empleado: id_empleado_agenda,
      nota: nota,
    };

    dispatch(agregarAgendaAction(data));
  };

  const onUpdateEvent = (e) => {
    e.preventDefault();

    const data = {
      fecha: moment.utc(fechaForm).format("YYYY-MM-DD[T]HH:mm:ss") + ".000Z",
      titulo: titulo,
      id_cliente: state.clienteSeleccionado?.id,
      id_empleado: id_empleado_agenda,
      nota: nota,
    };

    dispatch(actualizarAgendaAction(agenda.row.id, data));
  };

  const deleteEvent = (index) => {
    dispatch(eliminarAgendaAction(agendas[index]));
  };

  const updateEvent = (index) => {
    const fechaEdicion = new Date(Date.parse(agendas[index].row.fecha));

    setModalEvent(true);
    setAgenda(agendas[index]);
    setFecha(fechaEdicion.getTime());
    setTitulo(agendas[index].row.titulo);
    setNota(agendas[index].row.nota);
  };

  const deleteEventByIdAgenda = (id_agenda) => {
    dispatch(eliminarAgendaAction({ id: id_agenda }));
  };

  const handleEventModalClose = () => {
    setModalEvent(false);
  };

  const respuestaFiltroFooter = (item) => (
    <div className="flex flex-wrap justify-content-end gap-2">
      <Button
        className="d-block mx-auto"
        onClick={() => deleteEventByIdAgenda(item.id)}
        variant="danger"
        size="sm"
      >
        Eliminar
      </Button>
    </div>
  );

  const RespuestaBusqueda = () => {
    let resultado = [];
    if (respuestaFiltro.data) {
      respuestaFiltro.data.map((item, index) => {
        const date = new Date(Date.parse(item.fecha));

        resultado.push(
          <>
            <Col sm={4} md={3}>
              <PrimeCard
                title={item.titulo}
                footer={respuestaFiltroFooter(item)}
              >
                <ListGroup variant="flush">
                  <ListGroup.Item style={{ padding: "0px" }}>
                    <i class="fas fa-calendar"></i> {date.toUTCString()}
                  </ListGroup.Item>
                  <ListGroup.Item style={{ padding: "0px" }}>
                    <i class="fas fa-clock"></i> {formatAMPM(date, true)}
                  </ListGroup.Item>
                  <ListGroup.Item style={{ padding: "0px" }}>
                    <i class="fas fa-user"></i> {item.cliente?.nombre_completo}
                  </ListGroup.Item>
                </ListGroup>
              </PrimeCard>
            </Col>
          </>
        );
      });
    }

    return resultado;
  };

  const onChangeDoctorAgenda = (id_doctor) => {
    dispatch(limpiarFiltroAction());
    setDoctorAgenda(id_doctor);
    dispatch(getAgendaAction(id_doctor));
    dispatch(getHorariosDisponiblesAction(id_doctor, fechaSeleccionada));
  };

  const timeConstraints = {
    minutes: {
      step: 5,
    },
  };

  const dateSelected = (date) => {
    const fecha = moment(date).format("YYYY-MM-DD");

    setFechaSeleccionada(fecha);
    setFecha(fecha);
    setFechaForm(fecha);
    if (id_empleado_agenda != null) {
      dispatch(getHorariosDisponiblesAction(id_empleado_agenda, fecha));
    }
  };

  const confirmarCita = (agenda) => {
    dispatch(confirmarCitaAction(agenda));
  };

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <FontAwesomeIcon icon={faArrowCircleLeft} />
      ) : (
        <FontAwesomeIcon icon={faArrowCircleRight} />
      );
    return (
      <Button size="lg" variant="link" onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    );
  };

  const handleOnTabSequenceChange = useCallback(({ oldIndex, newIndex }) => {
    setActiveTab(newIndex);
  }, []);

  const handleOnTabChange = useCallback((i) => {
    setActiveTab(i);
  }, []);

  const handleTabList = () => {
    console.log(tabList);
  };

  const headerAgenda = (month) => {
    const empleado = empleados.filter((e) => e.id === id_empleado_agenda);

    return (
      <>
        <h3 className="text-center">
          {empleado.length > 0 ? empleado[0].nombre : ""}: {month}
        </h3>
      </>
    );
  };

  return (
    <>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm mt-4"
      >
        <Card.Body>
          <h5 className="mb-4">Agenda</h5>

          <Row
            style={{ marginBottom: "10px" }}
            className="justify-content-md-end"
          >
            <Col xs={12} md={8}>
              <Button
                variant="info"
                style={{ float: "right", marginRight: "20px" }}
                size="sm"
                onClick={() => dispatch(comenzarAgregarPacienteAction())}
              >
                <i class="fas fa-plus"></i> Nuevo cliente
              </Button>
            </Col>
          </Row>

          <Col>
            {empleados.length > 0 && id_empleado_agenda != null && (
              <>
                <Tabs
                  ref={tabNode}
                  customStyle={customStyle}
                  activeIndex={
                    activeTab > tabItems.length - 1
                      ? tabItems.length - 1
                      : activeTab
                  }
                  onTabChange={handleOnTabChange}
                  tabItems={tabItems}
                  onTabSequenceChange={handleOnTabSequenceChange}
                >
                  <TabList
                    ref={tabList}
                    handleTabChange={
                      tabList.current && tabList.current.state.modalIsOpen
                        ? tabList.current?.toggleModal()
                        : null
                    }
                  >
                    {tabItems}
                  </TabList>
                </Tabs>
              </>
            )}
          </Col>
          {horarios_disponibles.length > 0 && (
            <>
              <br></br>
              <h5>Horarios disponibles</h5>
              <Table responsive>
                <thead>
                  <tr>
                    {horarios_disponibles.map((item, index) => {
                      return (
                        <th style={{ borderRight: "1px solid #e5e5e5" }}>
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
              </Table>
            </>
          )}

          <Row className="justify-content-center mb-4 mt-4">
            <Col>
              {
                <Filtro
                  defaultSelected="cliente_proximas_citas"
                  buscarTodo={false}
                  opciones={opcionesFiltro}
                  modelo={modelo}
                  params={{ id_empleado: id_empleado_agenda }}
                />
              }
            </Col>
          </Row>

          <Row className="mb-4">
            <Container>
              <Row>{respuestaFiltro.data && <RespuestaBusqueda />}</Row>
            </Container>
          </Row>

          <RevoCalendar
            events={agendas}
            style={{
              borderRadius: "5px",
              height: "600px",
            }}
            highlightToday={true}
            lang="es"
            primaryColor="#f5f8fb"
            secondaryColor="#fff"
            todayColor="#3B3966"
            textColor="#000"
            indicatorColor="orange"
            animationSpeed={300}
            sidebarWidth={230}
            detailWidth={400}
            showDetailToggler={true}
            showSidebarToggler={true}
            onePanelAtATime={false}
            allowDeleteEvent={true}
            allowAddEvent={true}
            sidebarDefault={false}
            openDetailsOnDateSelection={true}
            timeFormat24={false}
            showAllDayLabel={false}
            detailDateFormat="DD/MM/YYYY"
            deleteEvent={deleteEvent}
            updateEvent={updateEvent}
            addEvent={addEvent}
            dateSelected={(date) => dateSelected(date)}
            header={headerAgenda}
          />

          <TabsReact defaultActiveKey="sin-confirmar" className="mt-3" fill>
            <TabReact
              eventKey="sin-confirmar"
              title="Citas sin confirmar"
              className="mt-4"
            >
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Hora</th>
                    <th>Cliente</th>
                    <th>Teléfono</th>
                    <th>Nota</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {agendas &&
                    agendas.map((item, index) => {
                      if (
                        item.row?.confirmado === true ||
                        item.row.fecha.split("T")[0] != fechaSeleccionada
                      ) {
                        return "";
                      }

                      const date = new Date(Date.parse(item.row.fecha));
                      return (
                        <tr>
                          <td>{item.row.fecha.split("T")[0]}</td>
                          <td>{formatAMPM(date, true)}</td>
                          <td>
                            {item.row.cliente?.nombre_completo ??
                              "CLIENTE NO ENCONTRADO"}
                          </td>
                          <td>
                            {item.row.cliente?.telefono ??
                              "TELÉFONO NO ENCONTRADO"}
                          </td>
                          <td>{item.row.nota}</td>
                          <td>
                            <Fragment>
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                overlay={<Tooltip>Confirmar cita</Tooltip>}
                              >
                                <button
                                  onClick={() => confirmarCita(item.row)}
                                  className="btn btn-success btn-sm"
                                  style={{
                                    marginRight: "5px",
                                  }}
                                >
                                  <i class="fas fa-check"></i>
                                </button>
                              </OverlayTrigger>
                            </Fragment>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TabReact>
            <TabReact
              eventKey="confirmadas"
              title="Citas confirmadas"
              className="mt-4"
            >
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Hora</th>
                    <th>Cliente</th>
                    <th>Teléfono</th>
                    <th>Nota</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {agendas &&
                    agendas.map((item, index) => {
                      if (
                        item.row.confirmado === false ||
                        item.row.fecha.split("T")[0] != fechaSeleccionada
                      ) {
                        return "";
                      }

                      const date = new Date(Date.parse(item.row.fecha));
                      return (
                        <tr>
                          <td>{item.row.fecha.split("T")[0]}</td>
                          <td>{formatAMPM(date, true)}</td>
                          <td>
                            {item.row.cliente?.nombre_completo ??
                              "CLIENTE NO ENCONTRADO"}
                          </td>
                          <td>
                            {item.row.cliente?.telefono ??
                              "TELÉFONO NO ENCONTRADO"}
                          </td>
                          <td>{item.row.nota}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TabReact>
          </TabsReact>
        </Card.Body>
      </Card>

      <Modal
        as={Modal.Dialog}
        size="sm"
        centered
        show={modalEvent}
        onHide={handleEventModalClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            {agenda ? "Editar evento" : "Agrega evento"}
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleEventModalClose}
          />
        </Modal.Header>
        <Form onSubmit={agenda ? onUpdateEvent : onConfirmEvent}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Titulo del evento"
                onChange={(e) => setTitulo(e.target.value)}
                value={titulo}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Datetime
                timeFormat={true}
                closeOnSelect={false}
                onChange={setFechaForm}
                utc={true}
                value={fechaForm}
                initialViewMode="time"
                dateFormat="YYYY-MM-DD HH:mm"
                timeConstraints={timeConstraints}
                renderInput={(propsDate, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type="text"
                      value={
                        fechaForm
                          ? moment.utc(fechaForm).format("YYYY-MM-DD HH:mm")
                          : ""
                      }
                      placeholder="Selecciona una fecha*"
                      onFocus={openCalendar}
                      onChange={(e) => setFechaForm(e.target.value)}
                    />
                  </InputGroup>
                )}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <AsyncTypeahead
                {...state}
                clearButton
                id="async-clientes"
                labelKey="nombre_completo"
                minLength={2}
                onInputChange={_handleInputChangeCliente}
                onSearch={_handleSearchCliente}
                onChange={onChangeCliente}
                placeholder="Buscar cliente*"
                renderMenuItemChildren={(option) => (
                  <div key={option.id}>
                    <img
                      alt={option.nombre_completo}
                      src={DefaultUser}
                      style={{
                        height: "24px",
                        marginRight: "10px",
                        width: "24px",
                      }}
                    />
                    <span>{option.nombre_completo}</span>
                  </div>
                )}
                useCache={false}
                selected={
                  agenda
                    ? [
                        {
                          id: agenda.row.cliente.id,
                          nombre_completo: agenda.row.cliente.nombre_completo,
                        },
                      ]
                    : undefined
                }
                disabled={agenda ? true : false}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                placeholder="Anotaciones"
                onChange={(e) => setNota(e.target.value)}
                value={nota}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleEventModalClose}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              {agenda ? "Actualizar" : "Agregar"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <AgregarPaciente />
    </>
  );
};
