import * as Types from "../types";

const initialState = {
  ventas: [],
  loadingVentas: false,
  productos: [],
  productosAgregados: [],
  tratamientoSeleccionado: {},
  totales: { subtotal: 0, total: 0, impuestos: 0, descuento: 0 },
  metodosPago: [],
  loadingProductosVenta: false,
  showModalVentaTratamientoPrecios: false,
  showModalVentaFinanciado: false,
  showModalVentaEmpleados: false,
  showModalMetodoPago: false,
  loadingAgregarVenta: false,
  loadingEliminarVenta: false,
  nota: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.LISTA_VENTAS:
      return {
        ...state,
        loadingVentas: true,
      };
    case Types.LISTA_VENTAS_EXITO:
      return {
        ...state,
        loadingVentas: false,
        ventas: action.payload,
      };
    case Types.LISTA_VENTAS_ERROR:
      return {
        ...state,
        loadingVentas: false,
      };
    case Types.LISTA_PRODUCTOS_VENTA:
      return {
        ...state,
        loadingProductosVenta: true,
      };
    case Types.LISTA_PRODUCTOS_VENTA_EXITO:
      return {
        ...state,
        loadingProductosVenta: false,
        productos: action.payload,
      };
    case Types.LISTA_PRODUCTOS_VENTA_ERROR:
      return {
        ...state,
        loadingProductosVenta: false,
        error: action.payload,
      };
    case Types.AGREGAR_PRODUCTO_VENTA:
      return {
        ...state,
        productosAgregados: state.productosAgregados.concat(action.payload)
      };
    case Types.ACTUALIZAR_PRODUCTO_VENTA:
      return {
        ...state,
        productosAgregados: state.productosAgregados.map((producto) =>
          producto.key === action.payload.key &&
          producto.tipo == action.payload.tipo
            ? (producto = action.payload)
            : producto
        )
      };
    case Types.ACTUALIZAR_PRODUCTOS_VENTA:
      return {
        ...state,
        productosAgregados: state.payload
      };
    case Types.AGREGAR_EMPLEADO_VENTA:
      return {
        ...state,
        productosAgregados: state.productosAgregados.map((producto) =>
          producto.key === action.payload.key &&
          producto.tipo == action.payload.tipo
            ? (producto = action.payload)
            : producto
        ),
      };
    case Types.ELIMINAR_PRODUCTO_VENTA:
      return {
        ...state,
        productosAgregados: state.productosAgregados.filter(
          (producto) => producto.key != action.payload.key
        )
      };
    case Types.RECALCULAR_TOTALES_EXITO:
      return {
        ...state,
        totales: action.payload,
      };
    case Types.RECALCULAR_TOTALES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case Types.LIMPIAR_VENTA_TOTALES:
      return {
        ...state,
        totales: { subtotal: 0, total: 0, impuestos: 0, descuento: 0 },
      };
    case Types.COMENZAR_FINANCIAR_TRATAMIENTO:
      return {
        ...state,
        tratamientoSeleccionado: action.payload,
        showModalVentaFinanciado: true,
      };
    case Types.MODAL_VENTA_TRATAMIENTOS_PRECIOS:
      return {
        ...state,
        showModalVentaTratamientoPrecios: action.payload,
      };
    case Types.MODAL_VENTA_FINANCIADO:
      return {
        ...state,
        showModalVentaFinanciado: action.payload,
      };
    case Types.COMENZAR_SELECCIONAR_EMPLEADO:
      return {
        ...state,
        tratamientoSeleccionado: action.payload,
        showModalVentaEmpleados: true,
      };
    case Types.MODAL_VENTA_EMPLEADOS:
      return {
        ...state,
        showModalVentaEmpleados: action.payload,
      };
    case Types.METODO_PAGO_VENTA:
      return {
        ...state,
        metodoPago: action.payload,
        showModalMetodoPago: false,
      };
    case Types.MODAL_METODO_PAGO_VENTA:
      return {
        ...state,
        showModalMetodoPago: action.payload,
      };
    case Types.AGREGAR_VENTA:
      return {
        ...state,
        loadingAgregarVenta: true,
      };
    case Types.AGREGAR_VENTA_EXITO:
      return {
        ...state,
        loadingAgregarVenta: false,
        totales: { subtotal: 0, total: 0, impuestos: 0, descuento: 0 },
        productosAgregados: [],
        tratamientoSeleccionado: {},
        metodosPago: [],
        nota: "",
      };
    case Types.AGREGAR_VENTA_ERROR:
      return {
        ...state,
        loadingAgregarVenta: false,
        error: action.payload,
      };
    case Types.ELIMINAR_VENTA:
      return {
        ...state,
        loadingEliminarVenta: true,
      };
    case Types.ELIMINAR_VENTA_EXITO:
      return {
        ...state,
        loadingEliminarVenta: false
      };
    case Types.ELIMINAR_VENTA_ERROR:
      return {
        ...state,
        loadingEliminarVenta: false,
        error: action.payload
      };
    case Types.ACTUALIZAR_DESCUENTO_PRODUCTOS_VENTA:
      return {
        ...state,
        productosAgregados: state.productosAgregados.map((producto) => {
          producto.descuento_general = action.payload.descuento_general;
          producto.descuento_tipo = action.payload.tipo;
          producto.descuento_porcentaje = action.payload.porcentaje_valor;
          producto.descuento_monto = action.payload.monto_valor;

          return producto;
        })
      };

    case Types.ACTUALIZAR_VENTA_STATE:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
}
