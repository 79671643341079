import * as Types from "../types";

import clienteAxios from "../config/axios";
import Swal from "sweetalert2";
import { loadingRequest } from "./axiosActions";

export function getVentasAction(params = null) {
  return async (dispatch) => {
    dispatch(getVentas());
    try {
      const response = await clienteAxios.get("venta/list", { params: params });
      if (response.data.data) {
        dispatch(getVentasExito(response.data.data));
      }
    } catch (error) {
      dispatch(getVentasError(error));
    }
  };
}

const getVentas = () => ({
  type: Types.LISTA_VENTAS,
});

const getVentasExito = (ventas) => ({
  type: Types.LISTA_VENTAS_EXITO,
  payload: ventas,
});

const getVentasError = (error) => ({
  type: Types.LISTA_VENTAS_ERROR,
  payload: error,
});

export function getProductosAction() {
  return async (dispatch) => {
    dispatch(getProductos());
    try {
      const response = await clienteAxios.get("productos/categorias/productos");
      if (response.data.data) {
        dispatch(getProductosExito(response.data.data));
      }
    } catch (error) {
      dispatch(getProductosError(error));
    }
  };
}

const getProductos = () => ({
  type: Types.LISTA_PRODUCTOS_VENTA,
});

const getProductosExito = (productos) => ({
  type: Types.LISTA_PRODUCTOS_VENTA_EXITO,
  payload: productos,
});

const getProductosError = (error) => ({
  type: Types.LISTA_PRODUCTOS_VENTA_ERROR,
  payload: error,
});

export function agregarProductoAction(producto) {
  return (dispatch) => {
    dispatch(agregarProducto(producto));
  };
}

const agregarProducto = (producto) => ({
  type: Types.AGREGAR_PRODUCTO_VENTA,
  payload: producto,
});

export function actualizarProductoAction(producto) {
  return (dispatch) => {
    dispatch(actualizarProducto(producto));
  };
}

const actualizarProducto = (producto) => ({
  type: Types.ACTUALIZAR_PRODUCTO_VENTA,
  payload: producto,
});

export function actualizarProductosAction(productos) {
  return (dispatch) => {
    dispatch(actualizarProductos(productos));
  };
}

const actualizarProductos = (productos) => ({
  type: Types.ACTUALIZAR_PRODUCTOS_VENTA,
  payload: productos,
});

export function agregarEmpleadoProductoVentaAction(producto) {
  return (dispatch) => {
    dispatch(agregarEmpleadoProductoVenta(producto));
  };
}

const agregarEmpleadoProductoVenta = (producto) => ({
  type: Types.AGREGAR_EMPLEADO_VENTA,
  payload: producto,
});

export function eliminarProductoAction(producto) {
  return (dispatch) => {
    dispatch(eliminarProducto(producto));
  };
}

const eliminarProducto = (producto) => ({
  type: Types.ELIMINAR_PRODUCTO_VENTA,
  payload: producto,
});

export function recalcularTotalesAction(productos) {
  return async (dispatch) => {
    try {
      const response = await clienteAxios.put("venta/recalcular-totales", {
        productos: productos,
      });
      if (response.data.data) {
        dispatch(recalcularTotalesExito(response.data.data));
      }
    } catch (error) {
      dispatch(recalcularTotalesError(error));
    }
  };
}

const recalcularTotalesExito = (totales) => ({
  type: Types.RECALCULAR_TOTALES_EXITO,
  payload: totales,
});

const recalcularTotalesError = (error) => ({
  type: Types.RECALCULAR_TOTALES_ERROR,
  payload: error,
});

export function limpiarTotalesAction() {
  return (dispatch) => {
    dispatch(limpiarTotales());
  };
}

const limpiarTotales = () => ({
  type: Types.LIMPIAR_VENTA_TOTALES,
});

export function showModalTratamientoPreciosAction(estatus) {
  return (dispatch) => {
    dispatch(showModalTratamientoPrecios(estatus));
  };
}

const showModalTratamientoPrecios = (estatus) => ({
  type: Types.MODAL_VENTA_TRATAMIENTOS_PRECIOS,
  payload: estatus,
});

export function comenzarFinanciarTratamientoAction(tratamiento) {
  return (dispatch) => {
    dispatch(comenzarFinanciarTratamiento(tratamiento));
  };
}

const comenzarFinanciarTratamiento = (tratamiento) => ({
  type: Types.COMENZAR_FINANCIAR_TRATAMIENTO,
  payload: tratamiento,
});

export function showModalFinanciadoAction(estatus) {
  return (dispatch) => {
    dispatch(showModalFinanciado(estatus));
  };
}

const showModalFinanciado = (estatus) => ({
  type: Types.MODAL_VENTA_FINANCIADO,
  payload: estatus,
});

export function comenzarSeleccionarEmpleadoAction(tratamiento) {
  return (dispatch) => {
    dispatch(comenzarSeleccionarEmpleado(tratamiento));
  };
}

const comenzarSeleccionarEmpleado = (tratamiento) => ({
  type: Types.COMENZAR_SELECCIONAR_EMPLEADO,
  payload: tratamiento,
});

export function showModalEmpleadoAction(estatus) {
  return (dispatch) => {
    dispatch(showModalEmpleado(estatus));
  };
}

const showModalEmpleado = (estatus) => ({
  type: Types.MODAL_VENTA_EMPLEADOS,
  payload: estatus,
});

export function showModalMetodoPagoAction(estatus) {
  return (dispatch) => {
    dispatch(showModalMetodoPago(estatus));
  };
}

const showModalMetodoPago = (estatus) => ({
  type: Types.MODAL_METODO_PAGO_VENTA,
  payload: estatus,
});

export function metodoPagoAction(metodo) {
  return (dispatch) => {
    dispatch(metodoPago(metodo));
  };
}

const metodoPago = (metodoPago) => ({
  type: Types.METODO_PAGO_VENTA,
  payload: metodoPago,
});

export function agregarVentaAction(venta, stateVenta, descuento = null) {
  return async (dispatch) => {
    dispatch(loadingRequest(true));

    dispatch(agregarVenta());
    try {
      const response = await clienteAxios.post("venta/agregar", venta);
      if (response.data.data) {
        Swal.fire("¡Muy bien!", response.data.message, "success");
        dispatch(agregarVentaExito(response.data.data));
        dispatch(loadingRequest(false));

        stateVenta.ventaRealizada = true;

        if (descuento) {
          descuento.tipo = "MONTO";
          descuento.porcentaje_valor = 0;
          descuento.monto_valor = 0;
          descuento.cupon_valor = "";
          descuento.descuento_general = false;
          descuento.descuento_por_cupon = false;
        }

        const ticketResponse = await clienteAxios.get(
          "imprimir/venta/" + response.data.data.id,
          { responseType: "blob" }
        );
        const file = new Blob([ticketResponse.data], {
          type: "application/pdf",
        });

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    } catch (error) {
      dispatch(agregarVentaError(error));
    }

    dispatch(loadingRequest(false));
  };
}

const agregarVenta = () => ({
  type: Types.AGREGAR_VENTA,
});

const agregarVentaExito = (response) => ({
  type: Types.AGREGAR_VENTA_EXITO,
});

const agregarVentaError = (error) => ({
  type: Types.AGREGAR_VENTA_ERROR,
});

export function eliminarVentaAction(id_venta, id_motivo) {
  return async (dispatch) => {
    dispatch(eliminarVenta());
    try {
      const response = await clienteAxios.delete(
        "venta/eliminar/" + id_venta + "?id_motivo=" + id_motivo
      );
      if (response.data.data) {
        Swal.fire("¡Muy bien!", response.data.message, "success");
        dispatch(eliminarVentaExito(response.data.data));
      }
    } catch (error) {
      dispatch(eliminarVentaError(error));
    }
  };
}
const eliminarVenta = () => ({
  type: Types.ELIMINAR_VENTA,
});

const eliminarVentaExito = (response) => ({
  type: Types.ELIMINAR_VENTA_EXITO,
  payload: response,
});

const eliminarVentaError = (error) => ({
  type: Types.ELIMINAR_VENTA_ERROR,
  payload: error,
});

export function imprimirVenta(id_venta) {
  return async (dispatch) => {
    try {
      const response = await clienteAxios.get("imprimir/venta/" + id_venta, {
        responseType: "blob",
      });
      const file = new Blob([response.data], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.log(error);
    }
  };
}

export function actualizarDescuentoGeneralAction(descuento) {
  return (dispatch) => {
    dispatch(actualizarDescuentoGeneral(descuento));
  };
}

const actualizarDescuentoGeneral = (descuento) => ({
  type: Types.ACTUALIZAR_DESCUENTO_PRODUCTOS_VENTA,
  payload: descuento,
});

export function actualizarVentaStateAction(state) {
  return (dispatch) => {
    dispatch(actualizarVentaState(state));
  };
}

const actualizarVentaState = (state) => ({
  type: Types.ACTUALIZAR_VENTA_STATE,
  payload: state,
})