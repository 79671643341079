import * as Types from "../types";
import clienteAxios from "../config/axios";
import { loadingRequest } from "./axiosActions";
import Swal from "sweetalert2";
import { eliminarRegistroFiltroAction } from "./filtroActions";

export function getAgendaAction(id_empleado) {
  return async (dispatch) => {
    dispatch(getAgenda());
    try {
      const response = await clienteAxios.get("agenda/list", {
        params: { id_empleado },
      });
      if (response.data.data) {
        const agendas = response.data.data.map(function (agenda) {
          const date = new Date(Date.parse(agenda.fecha));
          return {
            id: agenda.id,
            name: agenda.titulo,
            subtitle: `<small style="font-weight:lighter;font-size:0.84rem"> ${agenda.nota ?? ''} <p style="font-weight:bold;font-size:0.84rem">Creado por: ${agenda.creado_por?.name}</p> </small>`,
            date: date.getTime() + date.getTimezoneOffset() * 60000,
            allDay: false,
            extra: {
              icon: "M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09           4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z",
              text: agenda.cliente?.nombre_completo ?? "CLIENTE NO ENCONTRADO"
            },
            details: [
              {
                icon: "M19.23,15.26 L16.69,14.97 C16.08,14.9 15.48,15.11 15.05,15.54 L13.21,17.38 C10.38,15.94 8.06,13.63 6.62,10.79 L8.47,8.94 C8.9,8.51 9.11,7.91 9.04,7.3 L8.75,4.78 C8.63,3.77 7.78,3.01 6.76,3.01 L5.03,3.01 C3.9,3.01 2.96,3.95 3.03,5.08 C3.56,13.62 10.39,20.44 18.92,20.97 C20.05,21.04 20.99,20.1 20.99,18.97 L20.99,17.24 C21,16.23 20.24,15.38 19.23,15.26 Z",
                text: agenda.cliente?.telefono ?? "TELÉFONO NO ENCONTRADO"
              },
              {
                icon: "",
                text: agenda.confirmado ? "<span style='color:green;font-weight:bold'><b>Confirmado</b></span>" : "<span style='color:red;font-weight:bold'>Sin confirmar</span>"
              }
            ],
            row: agenda,
          };
        });
        dispatch(getAgendaExito(agendas));
      }
    } catch (error) {
      console.log(error);
      dispatch(getAgendaError(error));
    }
  };
}

const getAgenda = () => ({
  type: Types.LISTA_AGENDA,
});

const getAgendaExito = (agenda) => ({
  type: Types.LISTA_AGENDA_EXITO,
  payload: agenda,
});

const getAgendaError = (error) => ({
  type: Types.LISTA_AGENDA_ERROR,
  payload: error,
});

export function agregarAgendaAction(parameters) {
  return async (dispatch) => {
    try {
      dispatch(loadingRequest(true));
      const response = await clienteAxios.post("agenda/agregar", parameters);
      if (response.data.data) {
        const date = new Date(Date.parse(response.data.data.fecha));
        const agenda = {
          id: response.data.data.id,
          name: response.data.data.titulo,
          subtitle: `<small style="font-weight:lighter;font-size:0.84rem"> ${response.data.data.nota ?? ''} <p style="font-weight:bold;font-size:0.84rem"> Creado por: ${response.data.data.creado_por?.name} </p> </small>`,
          date: date.getTime() + date.getTimezoneOffset() * 60000,
          allDay: false,
          extra: {
            icon: "M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09           4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z",
            text: response.data.data.cliente.nombre_completo,
          },
          details: [
            {
              icon: "M19.23,15.26 L16.69,14.97 C16.08,14.9 15.48,15.11 15.05,15.54 L13.21,17.38 C10.38,15.94 8.06,13.63 6.62,10.79 L8.47,8.94 C8.9,8.51 9.11,7.91 9.04,7.3 L8.75,4.78 C8.63,3.77 7.78,3.01 6.76,3.01 L5.03,3.01 C3.9,3.01 2.96,3.95 3.03,5.08 C3.56,13.62 10.39,20.44 18.92,20.97 C20.05,21.04 20.99,20.1 20.99,18.97 L20.99,17.24 C21,16.23 20.24,15.38 19.23,15.26 Z",
              text: response.data.data.cliente?.telefono ?? "TELÉFONO NO ENCONTRADO"
            },
            {
              icon: "",
              text: response.data.data.confirmado ? "<span style='color:green;font-weight:bold'><b>Confirmado</b></span>" : "<span style='color:red;font-weight:bold'>Sin confirmar</span>"
            }
          ],
          row: response.data.data,
        };
        console.log(agenda);
        dispatch(agregarAgendaExito(agenda));
        Swal.fire("¡Muy bien!", "Acción realizada con éxito", "success");
      }
    } catch (error) {
      dispatch(agregarAgendaError(error));
    }

    dispatch(loadingRequest(false));
  };
}

const agregarAgendaExito = (agenda) => ({
  type: Types.AGREGAR_AGENDA_EXITO,
  payload: agenda,
});

const agregarAgendaError = (error) => ({
  type: Types.AGREGAR_AGENDA_ERROR,
  payload: error,
});

export function actualizarAgendaAction(id_agenda, parameters) {
  return async (dispatch) => {
    try {
      dispatch(loadingRequest(true));
      const response = await clienteAxios.put("agenda/actualizar/" + id_agenda, parameters);
      if (response.data.data) {
        const date = new Date(Date.parse(response.data.data.fecha));
        const agenda = {
          id: response.data.data.id,
          name: response.data.data.titulo,
          subtitle: `<small style="font-weight:lighter;font-size:0.84rem"> ${response.data.data.nota ?? ''} <p style="font-weight:bold;font-size:0.84rem"> Creado por: ${response.data.data.creado_por?.name} </p> </small>`,
          date: date.getTime() + date.getTimezoneOffset() * 60000,
          allDay: false,
          extra: {
            icon: "M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09           4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z",
            text: response.data.data.cliente.nombre_completo,
          },
          details: [
            {
              icon: "M19.23,15.26 L16.69,14.97 C16.08,14.9 15.48,15.11 15.05,15.54 L13.21,17.38 C10.38,15.94 8.06,13.63 6.62,10.79 L8.47,8.94 C8.9,8.51 9.11,7.91 9.04,7.3 L8.75,4.78 C8.63,3.77 7.78,3.01 6.76,3.01 L5.03,3.01 C3.9,3.01 2.96,3.95 3.03,5.08 C3.56,13.62 10.39,20.44 18.92,20.97 C20.05,21.04 20.99,20.1 20.99,18.97 L20.99,17.24 C21,16.23 20.24,15.38 19.23,15.26 Z",
              text: response.data.data.cliente?.telefono ?? "TELÉFONO NO ENCONTRADO"
            },
            {
              icon: "",
              text: response.data.data.confirmado ? "<span style='color:green;font-weight:bold'><b>Confirmado</b></span>" : "<span style='color:red;font-weight:bold'>Sin confirmar</span>"
            }
          ],
          row: response.data.data,
        };
        dispatch(actualizarAgendaExito(agenda));
        Swal.fire("¡Muy bien!", "Acción realizada con éxito", "success");
      }
    } catch (error) {
      dispatch(actualizarAgendaError(error));
    }

    dispatch(loadingRequest(false));
  };
}

const actualizarAgendaExito = (agenda) => ({
  type: Types.ACTUALIZAR_AGENDA_EXITO,
  payload: agenda,
});

const actualizarAgendaError = (error) => ({
  type: Types.ACTUALIZAR_AGENDA_ERROR,
  payload: error,
});

export function eliminarAgendaAction(agenda) {
  return async (dispatch) => {
    try {
      const response = await clienteAxios.delete(
        "agenda/eliminar/" + agenda.id
      );
      if (response.data.data) {
        dispatch(eliminarAgendaExito(agenda));
        dispatch(eliminarRegistroFiltroAction(agenda.id));
        Swal.fire("¡Muy bien!", "Acción realizada con éxito", "success");
      }
    } catch (error) {
      dispatch(eliminarAgendaError(error));
    }
  };
}

const eliminarAgendaExito = (agenda) => ({
  type: Types.ELIMINAR_AGENDA_EXITO,
  payload: agenda,
});

const eliminarAgendaError = (error) => ({
  type: Types.ELIMINAR_AGENDA_ERROR,
  payload: error,
});

export function getHorariosDisponiblesAction(id_empleado, fecha) {
  return async (dispatch) => {
    dispatch(getHorariosDisponibles());
    try {
      const response = await clienteAxios.get(
        "agenda/horarios-disponibles/" + id_empleado + "/" + fecha
      );
      if (response.data.data) {
        dispatch(getHorariosDisponiblesExito(response.data.data));
      }
    } catch (error) {
      console.log(error);
      dispatch(getHorariosDisponiblesError(error));
    }
  };
}

const getHorariosDisponibles = () => ({
  type: Types.LISTA_HORARIOS_DISPONIBLES,
});

const getHorariosDisponiblesExito = (horarios) => ({
  type: Types.LISTA_HORARIOS_DISPONIBLES_EXITO,
  payload: horarios,
});

const getHorariosDisponiblesError = (error) => ({
  type: Types.LISTA_HORARIOS_DISPONIBLES_ERROR,
  payload: error,
});

export function confirmarCitaAction(agenda) {
  return async (dispatch) => {
    try {
      const response = await clienteAxios.put("agenda/confirmar/" + agenda.id);
      if (response.data.data) {
        const date = new Date(Date.parse(response.data.data.fecha));
        const agenda = {
          id: response.data.data.id,
          name: response.data.data.titulo,
          subtitle: `<small style="font-weight:lighter;font-size:0.84rem"> ${response.data.data.nota ?? ''} <p style="font-weight:bold;font-size:0.84rem"> Creado por: ${response.data.data.creado_por?.name} </p> </small>`,
          date: date.getTime() + date.getTimezoneOffset() * 60000,
          allDay: false,
          extra: {
            icon: "M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09           4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z",
            text: response.data.data.cliente?.nombre_completo ?? "CLIENTE NO ENCONTRADO"
          },
          details: [
            {
              icon: "M19.23,15.26 L16.69,14.97 C16.08,14.9 15.48,15.11 15.05,15.54 L13.21,17.38 C10.38,15.94 8.06,13.63 6.62,10.79 L8.47,8.94 C8.9,8.51 9.11,7.91 9.04,7.3 L8.75,4.78 C8.63,3.77 7.78,3.01 6.76,3.01 L5.03,3.01 C3.9,3.01 2.96,3.95 3.03,5.08 C3.56,13.62 10.39,20.44 18.92,20.97 C20.05,21.04 20.99,20.1 20.99,18.97 L20.99,17.24 C21,16.23 20.24,15.38 19.23,15.26 Z",
              text: response.data.data.cliente?.telefono ?? "TELÉFONO NO ENCONTRADO"
            },
            {
              icon: "",
              text: response.data.data.confirmado ? "<span style='color:green;font-weight:bold'><b>Confirmado</b></span>" : "<span style='color:red;font-weight:bold'>Sin confirmar</span>"
            }
          ],
          row: response.data.data,
        };
        dispatch(confirmarCitaExito(agenda));
        Swal.fire("¡Muy bien!", response.data.message, "success");
      }
    } catch (error) {
      dispatch(confirmarCitaError(error));
    }
  };
}

const confirmarCitaExito = (agenda) => ({
  type: Types.CONFIRMAR_CITA_EXITO,
  payload: agenda,
});

const confirmarCitaError = (error) => ({
  type: Types.CONFIRMAR_CITA_ERROR,
  payload: error,
});
