import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactDatatable from '@mkikets/react-datatable'
import { ConfirmModal } from '../../components/ConfirmModal';
import { eliminarGastoAction } from '../../actions/cajaMovimientosActions';

export default props => {

  const dispatch = useDispatch();

  const config = {
    sort: { column: 'id', order: 'desc'},
    button: {
      excel: true,
      print: true
    }
  }

  const eliminarGasto = (record) => {
    const callbacks = [
        {
            event: dispatch,
            action: eliminarGastoAction,
            args: [record]
        }
    ]

    ConfirmModal(callbacks);
}
  
  const columns = [
    {
      key: 'id',
      text: 'ID',
      align: 'left',
      sortable: true
    },
    {
      text: "Tipo Movimiento",
      sortable: true,
      cell: record => {
        return record.tipo_movimiento?.tipo_movimiento
      }
    },
    {
      key: 'descripcion',
      text: 'Descripcion',
      className: 'descripcion',
      align: 'left',
      sortable: true
    },
    {
      key: 'monto',
      text: 'Monto',
      className: 'monto',
      align: 'left',
      sortable: true
    },
    {
      key: 'created_at',
      text: 'Fecha',
      align: 'left',
      sortable: true
    },
    {
      key: 'action',
      text: 'Acciones',
      className: 'action',
      width: 100,
      align: 'left',
      sortable: false,
      cell: record => {
        return record.tipo_movimiento?.tipo_contabilidad == 'EGRESO' ? (
          <Fragment>
            <button
              className='btn btn-danger btn-sm'
              style={{ marginRight: '5px' }}
              onClick={() => eliminarGasto(record)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        ) : ''
      }
    }
  ]

  return (
    <>
      <ReactDatatable
        config={config}
        hover
        records={props.movimientos}
        columns={columns}
      />
    </>
  )
}
