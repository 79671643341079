import React, { useState } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  showModalAgregarProductoAction,
  agregarProductoAction,
} from "../../actions/productosActions";
import { getBase64 } from "../../helpers";

export default (props) => {
  const dispatch = useDispatch();

  const { showModalAgregarProducto } = useSelector((state) => state.productos);
  const { unidadesMedicion } = useSelector((state) => state.unidadesMedicion);
  const { categoriasProductos } = useSelector(
    (state) => state.categoriasProductos
  );

  const [edicion, guardar] = useState({
    nombre: "",
    descripcion: "",
    precio: "",
    codigo: "",
    imagen: null,
    financiamiento_proveedor: false,
    solo_servicio: false,
  });

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(agregarProductoAction(edicion));
  };

  const handleClose = () => {
    dispatch(showModalAgregarProductoAction(false));
  };

  const onChangeForm = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeFileForm = (e) => {
    getBase64(e.target.files[0], (result) => {
      guardar({
        ...edicion,
        imagen: result,
      });
    });

    guardar({
      ...edicion,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCheckbox = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModalAgregarProducto}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className="h6">Agregar producto</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Financiamiento por proveedor</Form.Label>
                <Form.Check
                  type="switch"
                  name="financiamiento_proveedor"
                  checked={edicion.financiamiento_proveedor}
                  onChange={onChangeCheckbox}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Solo para servicios</Form.Label>
                <Form.Check
                  type="switch"
                  name="solo_servicio"
                  checked={edicion.solo_servicio}
                  onChange={onChangeCheckbox}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="nombre"
              placeholder="Nombre tratamiento"
              onChange={onChangeForm}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Descripcion</Form.Label>
            <Form.Control
              type="text"
              name="descripcion"
              placeholder="Descripcion"
              onChange={onChangeForm}
            />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Codigo</Form.Label>
                <Form.Control
                  type="text"
                  name="codigo"
                  placeholder="Codigo"
                  onChange={onChangeForm}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Precio venta</Form.Label>
                <Form.Control
                  type="number"
                  name="precio"
                  placeholder="Precio"
                  step="any"
                  onChange={onChangeForm}
                  min="0"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Categoria</Form.Label>
                <Form.Select
                  name="id_categoria_producto"
                  aria-label="Default select example"
                  onChange={onChangeForm}
                  className="mb-2"
                >
                  <option value="0">Selecciona una categoria</option>
                  {categoriasProductos.map((categoria) => (
                    <option value={categoria.id}> {categoria.nombre} </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Unidad de medición</Form.Label>
                <Form.Select
                  name="id_unidad_medicion"
                  aria-label="Default select example"
                  onChange={onChangeForm}
                  className="mb-2"
                >
                  <option value="0">Selecciona una unidad de medicion</option>
                  {unidadesMedicion.map((unidadMedicion) => (
                    <option value={unidadMedicion.id}>
                      {" "}
                      {unidadMedicion.unidad}{" "}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Imagen</Form.Label>
            <Form.Control
              type="file"
              onChange={onChangeFileForm}
              name="imagen"
              accept=".png,.jpg,.jpeg,.gif"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
