import * as Types from '../types';

const initialState = {
    agendas: [],
    agenda: {},
    horarios_disponibles: [],
    loading: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_AGENDA:
            return {
                ...state,
                loading: true
            }
        case Types.LISTA_AGENDA_EXITO:
            return {
                ...state,
                loading: false,
                agendas: action.payload
            }
        case Types.LISTA_AGENDA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case Types.AGREGAR_AGENDA_EXITO:
            return {
                ...state,
                agendas: state.agendas.concat(action.payload)
            }
        case Types.AGREGAR_AGENDA_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case Types.ACTUALIZAR_AGENDA_EXITO:
            return {
                ...state,
                agendas: state.agendas.map( agenda => 
                    agenda.id === action.payload.id ? agenda = action.payload : agenda
                ),
            }
        case Types.ACTUALIZAR_AGENDA_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case Types.ELIMINAR_AGENDA_EXITO:
            return {
                ...state,
                agendas: state.agendas.filter( agenda => 
                    agenda.id != action.payload.id
                )
            }
        case Types.ELIMINAR_AGENDA_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case Types.LISTA_HORARIOS_DISPONIBLES:
            return {
                ...state,
                loading: true
            }
        case Types.LISTA_HORARIOS_DISPONIBLES_EXITO:
            return {
                ...state,
                loading: false,
                horarios_disponibles: action.payload
            }
        case Types.LISTA_HORARIOS_DISPONIBLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                horarios_disponibles: []
            }
        case Types.CONFIRMAR_CITA_EXITO:
            return {
                ...state,
                agendas: state.agendas.map( agenda =>
                    (agenda.id === action.payload.id) ? agenda = action.payload : agenda
                )
            }
        case Types.CONFIRMAR_CITA_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}