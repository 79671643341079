import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal, Button } from "@themesberg/react-bootstrap";
import ReactDatatable from "@mkikets/react-datatable";
import {
  showModalPacienteTratamientosAction,
  actualizarProductoAction,
} from "../../actions/pacientesActions";

import {
  getAbonosTratamientosAction,
  showModalAbonosAction,
} from "../../actions/abonosActions";
import ModalAbonos from "../Abonos/ModalAbonos";

export default (props) => {
  const dispatch = useDispatch();

  const { clienteTratamientos, showModalClienteTratamientos } = useSelector(
    (state) => state.pacientes
  );

  const columns = [
    {
      key: "id",
      text: "ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "fecha_tratamiento",
      text: "Fecha tratamiento",
      align: "left",
      sortable: true,
    },
    {
      key: "tratamiento.nombre",
      text: "Tratamiento",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.tratamiento?.nombre;
      },
    },
    {
      key: "total",
      text: "Total tratamiento $",
      align: "left",
      sortable: true,
    },
    {
      text: "Anticipo $",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.total - (record.tratamiento_financiado?.total_financiado ?? 0);
      },
    },
    {
      key: "tratamiento_financiado.total_financiado",
      text: "Total Financiado $",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.tratamiento_financiado?.total_financiado ?? 0;
      },
    },
    {
      key: "tratamiento_financiado.saldo",
      text: "Saldo restante $",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.tratamiento_financiado?.saldo ?? 0;
      },
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            {record.tratamiento_financiado != null && (
              <button
                className="btn btn-success btn-sm"
                onClick={() => getAbonos(record)}
                style={{ marginRight: "5px" }}
              >
                Ver Abonos
              </button>
            )}
          </Fragment>
        );
      },
    },
  ];

  const getAbonos = (record) => {
    dispatch(showModalAbonosAction(true));
    dispatch(getAbonosTratamientosAction(record.id_cliente, record));
  };

  const handleClose = () => {
    dispatch(showModalPacienteTratamientosAction(false));
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="xl"
        centered
        show={showModalClienteTratamientos}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Tratamientos del Paciente</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="table-wrapper table-responsive shadow-sm">
          <ReactDatatable
            hover
            records={clienteTratamientos}
            columns={columns}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalAbonos tabla="CLIENTES_TRATAMIENTOS" />
    </>
  );
};
