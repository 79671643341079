import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  agregarTratamientoAction,
  cerrarModalAgregarAction,
} from "../../actions/tratamientosActions";
import { getBase64 } from "../../helpers";

export default (props) => {
  const dispatch = useDispatch();

  const { showModalAgregar } = useSelector((state) => state.tratamientos);

  const [agregar, guardar] = useState({
    nombre: "",
    imagen: null,
  });

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(agregarTratamientoAction(agregar));
  };

  const handleClose = () => {
    dispatch(cerrarModalAgregarAction());
  };

  const onChangeForm = (e) => {
    guardar({
      ...agregar,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeFileForm = (e) => {
    getBase64(e.target.files[0], (result) => {
      guardar({
        ...agregar,
        imagen: result,
      });
    });

    guardar({
      ...agregar,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModalAgregar}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className="h6">Agregar tratamiento</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="nombre"
              placeholder="Nombre tratamiento"
              value={agregar.nombre}
              onChange={onChangeForm}
            />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Imagen</Form.Label>
            <Form.Control
              type="file"
              onChange={onChangeFileForm}
              name="imagen"
              accept=".png,.jpg,.jpeg,.gif"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
